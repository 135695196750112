import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import ClientStatementsController, {
    Props,
} from "./ClientStatementsController.web";
import { styles } from "./ClientReportStyles.web";
import ClientReportTable from "../../../components/src/Table/ClientReportsTable";
import { ClientStatementHeader, ClientStatementHeaderCondition } from "../../../components/src/Utils/dummyData";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import { printListItemsMsg, downloadDocs, paymentTypeUpdater } from "../../../components/src/Utils/utils";
import AdvanceSearchWeb from "../../../components/src/Search/AdvanceSearch.web";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";


const icons = require('./assets')

export class ClientStatements extends ClientStatementsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const { page, count, per_page,total_count, isLoading, statementData, isDelinquentFeeAvailable, clientName, CompanyName, clientAddress, pendingAmmount, columnTitle, disbursmentStartDate, excelURL, disbursmentEndDate, dueStartDate, dueEndDate } = this.state
        const dateObject = {
            disbursmentStartDate: disbursmentStartDate,
            disbursmentEndDate: disbursmentEndDate,
            dueStartDate: dueStartDate,
            dueEndDate: dueEndDate
        }

        return (
            <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.updatedWidthProvider}>
                        <Grid
                            container
                            direction="column"
                            style={{ paddingBottom: '20px' }}
                            className={classes.statementTableGrid}
                        >
                            <Box className="statementProvider">
                                Statements
                            </Box>
                            <Box className="clientDetailsProvider">
                                <Box className="ClientBox1">
                                    <span className="ClientBox1Header"> Contact Name : </span>
                                    <span className="ClientBox1value"> {clientName} </span>
                                </Box>
                                <Box className="ClientBox2">
                                    <span className="ClientBox2Header"> Company Name : </span>
                                    <span className="ClientBox2value"> {CompanyName} </span>
                                </Box>
                                <Box className="ClientBox3">
                                    <span className="ClientBox3Header"> Address : </span><span className="fontWeightProvider"> {clientAddress} </span>
                                </Box>
                            </Box>
                            <Grid container spacing={2} className={classes.searchGrid}>
                                <Grid item md={5} lg={5} xs={12} sm={12}>
                                    <AdvanceSearchWeb
                                        searchValue={this.state.search}
                                        handleInputChange={this.handleClientInput}
                                        handleSelectSearch={this.handleSelectSearch}
                                        options={this.state.clientOptionsResponse}
                                        loading={this.state.isClientsLoad1}
                                        isSearchIcon={true}
                                        width="100%"
                                    />
                                </Grid>
                                <Grid item md={6} lg={6} xs={12} sm={12}>
                                    <Box
                                        className="iconsHolder"
                                        flexDirection="row"
                                        alignItems="flex-end"
                                        gridColumnGap="1px"
                                        display="flex"
                                    >
                                        <CommonButtonWeb handleClick={() => this.clearStatementFilters()} btnText="Clear Filters" data-test-id="clear-statement-filters" classNames={classes.clearClientFilter} />
                                        <img data-test-id="excel-download" onClick={() => downloadDocs(excelURL)} alt="" src={icons.downloadExcel} style={{ cursor: "pointer" }} />
                                        <img data-test-id="pdf-download" src={icons.downloadPdfPNG} onClick={() => this.downloadPdf()} style={{ cursor: "pointer" }} />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="clientPayment">
                                Total Payment Pending
                                <Box className="paymentBox" sx={{ color: this.state.isAmountCredit ? '#20BB6E' : 'rgba(0, 0, 0, 0.87)' }}> {paymentTypeUpdater(pendingAmmount, this.state.isAmountCredit)} </Box>
                            </Box>
                        </Grid>
                        <Box>
                            <ClientReportTable
                                columns={isDelinquentFeeAvailable.length > 0 ? ClientStatementHeader : ClientStatementHeaderCondition}
                                isThreeDotsMenuNotRequired={true}
                                data={statementData}
                                loadingTableData={isLoading}
                                extraClassnames={classes.tableClass}
                                headerClasses={classes.tableHeaderClasses}
                                handleFilter={this.handleFilter}
                                filter={[columnTitle]}
                                handleTwoDateChange={this.handleDateChange}
                                isMoreDatesRequired={true}
                                dateObject={dateObject}
                            />
                        </Box>
                        <Box sx={{ marginTop: '35px' }}>
                            <CustomPagination
                                count={count}
                                page={page}
                                onPaginationChange={this.handleChangePage}
                                msg={printListItemsMsg(count, page, per_page,total_count)}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ClientStatements);
