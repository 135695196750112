import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { userRoles, keyNameArray, PUBLISHED_NEWS_STATUS, KYC_Status, ekycPaths } from './constant'
import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import { ObjectData, RefinedObject, RefinedNewsData, NewsData } from './types';
import { coloursArray } from "./dummyData"

const zipcodeToTimezone = require('zipcode-to-timezone');

export const setToken = (token: any) => {
  localStorage.setItem('auth_token', token)
}

export const getToken = () => {
  const token = localStorage.getItem('auth_token');
  return token || ''
}

export const setLocalforClient = (res: any) => {
  const myObject = {
    name: res.declaration_name,
    id: res.id,
    kyc_status: res.kyc_status,
  };
  localStorage.setItem("user", JSON.stringify(myObject));
  localStorage.setItem("auth_token", res.token);
  localStorage.setItem("account_role", res.account_role);
};

export const setLocalforAdmin = (res: any) => {
  const object = {
    name: res.name,
    id: res.id,
  };
  localStorage.setItem("account_role", res.role);
  localStorage.setItem("auth_token", res.token);
  localStorage.setItem("user", JSON.stringify(object));
};

export const logoutUser = async () => {
  let role: any = await localStorage.getItem("account_role");
  if (role === userRoles.client) {
    window.location.href = "/login";
  } else {
    window.location.href = "/admin/login";
  }
  localStorage.clear();
};

export const handleApiError = (errors: any) => {
  const isArrayOfStirng = typeof errors[0] === 'string';
  const errorMessages = isArrayOfStirng ? errors.join(", ") : errors?.map((error: any) => Object.values(error)).flat().join(", ");
  if (errorMessages.includes("Token has Expired") || errorMessages.includes("Invalid token")) {
    logoutUser();
  }
  toast.error(errorMessages);
  return "";
};

export const downloadFiles = (url: string) => {
  if (url) {
    const alink = document.createElement('a');
    alink.href = url;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const contentType = xhr.getResponseHeader('content-type');
        //@ts-ignore
        const fileExtension = getFileExtension(contentType);

        if (fileExtension) {
          //@ts-ignore
          const blob = new Blob([xhr.response], { type: contentType });
          const blobUrl = URL.createObjectURL(blob);

          alink.href = blobUrl;
          alink.setAttribute('download', `download.${fileExtension}`);
          alink.setAttribute('target', '_blank');
          alink.click();

          URL.revokeObjectURL(blobUrl);
        } else {
          // Handle files without extensions
          alink.setAttribute('download', '');
          alink.setAttribute('target', '_self');
          alink.click();
        }
      }
    };

    xhr.send();
  }
};

const getFileExtension = (contentType: string) => {
  const mimeTypes: Record<string, string> = {
    'application/pdf': 'pdf',
    'image/png': 'png',
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/gif': 'gif',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  };

  return mimeTypes[contentType] || '';
};

export const apiCall = (token: any, endPoint: string, method: string, body?: any) => {
  const header = {
    "Content-Type": "application/json",
    token: token,
  };

  const bodyHeader = {
    token: token
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  if (body) {

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(bodyHeader)
    );

    const fd = new FormData()
    fd.append(body.name, body.file);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      fd
    )
  } else {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId
}

export const downloadDocs = (url: any) => {
  if (url) {
    let alink = document.createElement('a');
    alink.href = url;

    const fileExtension = url.split('.').pop().toLowerCase();
    if (fileExtension === 'pdf') {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);

          alink.href = blobUrl;
          alink.setAttribute('download', 'download.pdf');
          alink.setAttribute('target', '_blank');
          alink.click();

          URL.revokeObjectURL(blobUrl);
        }
      };

      xhr.send();
    } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      alink.setAttribute('download', '');
      alink.setAttribute('target', '_self');
      alink.click();
    }
  }
}

export const printListItemsMsg = (
  count: any,
  pageNumber: number,
  itemsPerPage?: number,
  total_count: number = 0 
) => {
  if  (count && pageNumber && itemsPerPage) {
    const startItem = (pageNumber - 1) * itemsPerPage + 1;
    let endItem = pageNumber * itemsPerPage;
    if (endItem > total_count) {
      endItem = total_count;
    }
    return `Showing ${startItem} - ${endItem} of total ${total_count}`;
  }
   else {
    return "   ";
  }
};

export const getCommissionByScore = (arr: any[], score: number) => {
  for (const item of arr) {
    const { scoring_range, fee } = item;
    const [start, end] = scoring_range.split(" - ");
    const startValue = Number(start.trim());
    const endValue = Number(end.trim());

    if (score >= startValue && score <= endValue) {
      return fee;
    }
  }

  return "TBD";
};

export const getScoreFromFormula = (formula: string, ...values: any[]) => {
  try {
    let modifiedFormula = formula;
    for (let i = 0; i < values.length; i++) {
      const placeholder = String.fromCharCode(97 + i); // Use ASCII values to get the corresponding lowercase letter (a, b, c, ...)
      modifiedFormula = modifiedFormula.replace(new RegExp(placeholder, 'g'), values[i]);
    }
    const result = eval(modifiedFormula);
    return typeof result === "number" ? result.toFixed(2) : null;
  } catch (error) {
    return null;
  }
};

export const refineResponseData = (responseData: any) => {
  const refinedData: any[] = [];
  const excludeFile = responseData.account_block_corporate_types.name === "Sole proprietor" ? "articles_of_incorporations" : "certificates_of_registrations";

  keyNameArray.forEach((keyObj) => {
    const key = keyObj.name;
    const idToSend = keyObj.id;
    if (responseData.hasOwnProperty(key) && key !== excludeFile) {
      const id = idToSend;
      const files = responseData[key];
      const title = keyObj.value;
      const titleToSend = keyObj.name;
      const status = responseData[`${key}_status`] || false;
      refinedData.push({ title, files, status, id, titleToSend });
    }
  });

  const maxFiles = 9;
  const refinedResponseData = refinedData.slice(0, maxFiles);

  return refinedResponseData;
};

export const handleRedirectOnStatus = (arr: any, row: any, navigate: any) => {
  const redirectItem = arr.find((item: any) => item.title.includes(row.document_title));
  if (redirectItem) {
    const { component, renderId } = redirectItem;
    navigate(component, { id: row[renderId] });
  }
}

export const refinedClientData = (data: ObjectData[]): RefinedObject[] => {
  const refinedArray: RefinedObject[] = [];

  data?.forEach((objectData) => {
    if (objectData.attributes.company_name === null) {
      return
    }
    const refinedObject: RefinedObject = {
      id: objectData.id,
      companyName: objectData.attributes.company_name,
    };

    refinedArray.push(refinedObject);
  });
  return refinedArray;
};

export const removeUnderscoreAndCapitalize = (str: string) => {
  const words = str.split('_');
  const capitalizedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  const result = capitalizedWords.join(' ');
  return result;
}
export const isNegativeNumber = (value: string): boolean => {
  const trimmedValue = value.trim();
  if (trimmedValue === '-') {
    // If the string is just a single hyphen, it means it's not present in the data
    return false;
  }
  const numericValue = parseFloat(trimmedValue);
  return !isNaN(numericValue) && numericValue < 0;
};


export const refineNewsData = (responseData: NewsData[]): RefinedNewsData[] => {
  if (!Array.isArray(responseData)) {
    return [];
  }
  const refinedData: RefinedNewsData[] = responseData
    .filter((item) => item.attributes.status === PUBLISHED_NEWS_STATUS)
    .sort((a, b) => new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime())
    .slice(0, 10)
    .map(({ id, attributes }) => {
      const { title, body, created_at, images } = attributes;
      const titleImage = images.length > 0 ? images[0] : null;

      const dateObj = new Date(created_at);
      const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(dateObj);
      const day = dateObj.getDate();

      const formattedDate = `${month} ${day}`;

      return {
        title,
        body,
        titleImage,
        createdDate: formattedDate,
        id,
      };
    });

  return refinedData;
};

export const handleFormikErrors = (error: any, touch: any) => {
  return touch && Boolean(error);
}

export const handleFormikHelperText = (text: any, touch: any) => {
  return touch && text
}

export const transformData = (input: any[]) => {
  return input.map((obj: any, index: number) => {
    return {
      id: index + 1,
      file: { ...obj }
    };
  });
};

export const mergeFiles = (array1: Array<any>, array2: Array<any>): Array<File> => {
  const mergedFiles: File[] = [];
  array1.forEach((obj) => {
    if (obj.file) {
      const jsonString = JSON.stringify(obj);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const file = new File([blob], obj.file.name, { type: 'application/json' })
      mergedFiles.push(file);
    }
  });

  array2.forEach((obj) => {
    if (obj.file) {
      mergedFiles.push(obj.file);
    }
  });

  return mergedFiles;
};

const getCountryShortForm = (fullCountryName: string | null): string => {
  if (!fullCountryName) {
    return '';
  }

  const countryMap: { [key: string]: string } = {
    'Jamaica': 'JAM',
    'jamaica': 'JAM',
    'USA': 'US',
    'US': 'US',
    'us': 'US',
  };

  const shortForm = countryMap[fullCountryName];
  return shortForm || '';
}

export const handleClientFilteration = (data: any) => {
  const filteredData: any[] = []
  data?.accounts?.data.forEach((item: any) => {
    const updatedData = {
      agreement_date: item?.attributes?.agreement_date,
      id: item?.id,
      company_name: item?.attributes?.company_name,
      commission_due: item?.attributes?.commission_due,
      commission_past_due: item?.attributes?.commission_past_due,
      total_commission_due: item?.attributes?.total_commission_due,
      delinquent_fee: item?.attributes?.delinquent_fee,
      total_amount_due: item?.attributes?.total_amount_due,
      amount_disbursed: item?.attributes?.original_amount_disbursed,
      factor_commission_rate: item?.attributes?.factor_commission_rate,
      date_onboarding_completed: item?.attributes?.date_onboarding_completed,
      commission_accrued: item?.attributes?.commission_accrued,
      commission_collected: item?.attributes?.commission_collected,
      amount_coming_due_in_5_days: item?.attributes?.amount_coming_due_in_5_days,
      country_name: getCountryShortForm(item?.attributes?.country_name),
      declaration_name: item?.attributes?.declaration_name,
      factor_limit: item?.attributes?.factor_limit,
      is_delinquent_enable: item?.attributes?.is_delinquent_enable,
      is_paid: item?.attributes?.is_paid,
      sector_Name: item?.attributes?.sector === null ? 'Agriculture' : item?.attributes?.sector
    }

    filteredData.push(updatedData)
  })
  return filteredData
}

export const handleVelocityDataFilteration = (data: any) => {
  const filteredData: any[] = []
  data?.velocity_report?.forEach((item: any) => {
    const updatedData = {
      sector: item?.sector,
      percentage_change: item?.percentage_change,
      total_disbrused: item?.total_disbrused,
      total_disbrused_comparison_period: item?.total_disbrused_comparison_period,
    }

    filteredData.push(updatedData)
  })
  return filteredData
}

export const convertIntoCurrency = (currency: any) => {
  if (currency) {
    const cur = Number(currency);
    const formattedCurrency = cur.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    const currencyWithoutSymbol = formattedCurrency.replace('$', '');
    return currencyWithoutSymbol
  } else {
    return 0
  }
}

export const balanceConverter = (currency: any) => {
  if (currency) {
    const cur = String(currency);
    const parts = cur.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = parts[1]?.slice(0, 2) || '00';
    return `${integerPart}.${decimalPart}`;
  } else {
    return '0.00';
  }
}


export const getInvoiceReportEndpoint = (state: any) => {
  const { page, per_page, of_days_past_due, client, sector, country, disbursed_date_from,
    disbursed_date_to,
    factor_due_date_from,
    factor_due_date_to, } = state;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    of_days_past_due ? `of_days_past_due=${of_days_past_due}` : '',
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    disbursed_date_from ? `disbursed_date_from=${disbursed_date_from}` : '',
    disbursed_date_to ? `disbursed_date_to=${disbursed_date_to}` : '',
    factor_due_date_from ? `factor_due_date_from=${factor_due_date_from}` : '',
    factor_due_date_to ? `factor_due_date_to=${factor_due_date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_invoices_report?${queryString}`
}

export const getInvoiceReportCSVEndpoint = (state: any) => {
  const { page, per_page, of_days_past_due, client, sector, country, disbursed_date_from,
    disbursed_date_to,
    factor_due_date_from,
    factor_due_date_to, } = state;
  const arr = [
    of_days_past_due ? `of_days_past_due=${of_days_past_due}` : '',
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    disbursed_date_from ? `disbursed_date_from=${disbursed_date_from}` : '',
    disbursed_date_to ? `disbursed_date_to=${disbursed_date_to}` : '',
    factor_due_date_from ? `factor_due_date_from=${factor_due_date_from}` : '',
    factor_due_date_to ? `factor_due_date_to=${factor_due_date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `invoice_report_excel?${queryString}`
}

export const getGlobalReportEndpoint = (data: any) => {
  const { page, per_page, client, sector, date_to, date_from, country } = data;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    date_from ? `date_from=${date_from}` : '',
    date_to ? `date_to=${date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_global_revenue_report?${queryString}`
}

export const getGlobalReportCSVEndpoint = (data: any) => {
  const { client, sector, date_to, date_from, country } = data;
  const arr = [
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    date_from ? `date_from=${date_from}` : '',
    date_to ? `date_to=${date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `global_revenue_report_excel?${queryString}`
}

export const getVelocityReportEndPoint = (data: any) => {
  const { page, per_page, sectorSelection, mainPeriodEndDate, mainPeriodStartDate, comparisonPeriodStartDate, comparisonPeriodEndDate, sort_direction, sort_column } = data;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    sectorSelection.name ? `sector=${sectorSelection.name}` : '',
    sort_direction ? `sort_order=${sort_direction}` : '',
    sort_column ? `sort_column=${sort_column}` : '',
    mainPeriodStartDate ? `start_date=${moment(mainPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    mainPeriodEndDate ? `end_date=${moment(mainPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    comparisonPeriodStartDate ? `comparison_start_date=${moment(comparisonPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    comparisonPeriodEndDate ? `comparison_end_date=${moment(comparisonPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_velocity_report?${queryString}`
}

export const getVelocityReportCSVEndPoint = (data: any) => {
  const { sectorSelection, mainPeriodEndDate, mainPeriodStartDate, comparisonPeriodStartDate, comparisonPeriodEndDate, sort_direction, sort_column } = data;
  const arr = [
    sectorSelection.name ? `sector=${sectorSelection.name}` : '',
    sort_direction ? `sort_order=${sort_direction}` : '',
    sort_column ? `sort_column=${sort_column}` : '',
    mainPeriodStartDate ? `start_date=${moment(mainPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    mainPeriodEndDate ? `end_date=${moment(mainPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    comparisonPeriodStartDate ? `comparison_start_date=${moment(comparisonPeriodStartDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
    comparisonPeriodEndDate ? `comparison_end_date=${moment(comparisonPeriodEndDate, 'MMM DD, YYYY').format('DD-MMM-YYYY')}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `velocity_report_excel?${queryString}`
}

export const getPastDueEndpoint = (data: any) => {
  const { page, per_page, client, sector, date_to, date_from, country } = data;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    date_from ? `date_from=${date_from}` : '',
    date_to ? `date_to=${date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_past_due_report?${queryString}`
}

export const getPastDueCsvEndpoint = (data: any) => {
  const { client, sector, date_to, date_from, country } = data;
  const arr = [
    country ? `country=${country}` : '',
    sector.name ? `sector=${sector.name}` : '',
    client.name ? `client=${client.name}` : '',
    date_from ? `date_from=${date_from}` : '',
    date_to ? `date_to=${date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `past_due_report_excel?${queryString}`
}

export const handleIndustryFilter = (response: any) => {
  const filterdArray: any[] = []
  response.data?.forEach((item: any) => {
    const updatedData = {
      id: item?.id,
      name: item?.attributes?.name
    }
    filterdArray.push(updatedData)
  })
  return filterdArray
}

export const handleClientOptionFilter = (response: any) => {
  const filterdArray: any[] = []
  response.data?.forEach((item: any) => {
    const updatedData = {
      id: item?.id,
      name: item?.attributes?.company_name
    }
    filterdArray.push(updatedData)
  })
  return filterdArray
}

export const formatDate = (inputDate: any) => {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const dateObj = new Date(inputDate);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();

  const formattedDate = `${day}-${months[monthIndex]}-${year}`;
  return formattedDate;
}

export const reFormatDate = (inputDate: any) => {
  const convertedDate = inputDate.toString();

  if (convertedDate?.includes("GMT+0530 (India Standard Time)") || convertedDate.includes("Jamica") || convertedDate.includes("GMT-0400 (Eastern Daylight Time)") || convertedDate.includes("GMT+0100 (British Summer Time)") || convertedDate?.includes("EST") || convertedDate?.includes("UTC-5")) {
    return inputDate;
  }

  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const dateParts = convertedDate?.split("-");
  const day = parseInt(dateParts[0], 10);
  const month = months.indexOf(dateParts[1]);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return new Date();
  }

  const formattedDate = new Date(year, month, day).toString();
  return formattedDate;
}

export const invoicePoDateRange = (state: any) => {
  const {
    disbursed_date_from,
    disbursed_date_to,
    factor_due_date_from,
    factor_due_date_to,
  } = state;

  return {
    disbursed_date_from,
    disbursed_date_to,
    factor_due_date_from,
    factor_due_date_to,
  }
}

export const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const formatDate2 = (inputDate: string): string => {
  const months: string[] = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"

  ];

  const dateObj = new Date(inputDate);
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const handleClientStatementFilteration = (data: any, settlementDateValue: boolean) => {
  const transformedData: any[] = [];
  const sortedIds: any[] = []

  data?.statements?.data?.forEach((item: any) => {
    const transformedItem: any = {
      disbursement_date: item?.attributes?.disbursement_date,
      due_date: item?.attributes?.factor_due_date,
      amount_disbursed: item?.attributes?.original_amount_disbursed || "",
      factor_fee: item?.attributes?.original_factor_commission_due,
      delinquent_fee: item?.attributes?.delinquent_fee,
      Amount_to_be_Settled_to_CCI: item?.attributes?.amount_to_be_settled,
      invoice_PO: item?.attributes?.invoice_po,
      invoice_amount: item?.attributes?.invoice_po_amount,
      vendor_Buyer: item?.attributes?.buyer_name,
      invoice_PO_Date: item?.attributes?.invoice_po_date,
      CCI_settlement_date: item?.attributes?.cci_settement_date || "",
    };
    transformedData.push(transformedItem);

    if (item?.attributes?.payments && item?.attributes?.payments.data.length > 0) {
      item?.attributes?.payments.data.forEach((payment: any) => {
        const data: any = {
          disbursement_date: "",
          amount_disbursed: "",
          Amount_to_be_Settled_to_CCI: `( ${payment.attributes?.amount_to_be_settled} )`,
          invoice_amount: "PAYMENT",
          CCI_settlement_date: `( ${payment.attributes?.payment_date} )`,
        }
        transformedData.push(data);
      });
    }

    settlementDateValue && data?.credit_statements?.data.forEach((element: any) => {
      if (element?.attributes?.invoice_po_id == item?.id) {
        const creditReturnData: any = {
          disbursement_date: `( ${element?.attributes?.payment_date} )`,
          Amount_to_be_Settled_to_CCI: "",
          amount_disbursed: `( ${element?.attributes?.amount_to_be_settled} )`,
          invoice_amount: "CREDIT RETURN",
        }
        transformedData.push(creditReturnData);
        sortedIds.push(element?.id)
      }
    })
  });
  settlementDateValue && data?.credit_statements?.data.forEach((element: any) => {
    if (!sortedIds.includes(element?.id)) {
      const creditReturnData: any = {
        disbursement_date: `( ${element?.attributes?.payment_date} )`,
        Amount_to_be_Settled_to_CCI: "",
        amount_disbursed: `( ${element?.attributes?.amount_to_be_settled} )`,
        invoice_amount: "CREDIT RETURN",
      }
      transformedData.push(creditReturnData);
    }
  })
  return transformedData;
};

export const getClientStatementEndpoint = (data: any) => {
  const { page, per_page, disbursmentStartDate, disbursmentEndDate, columnTitle, dueStartDate, dueEndDate, searchSelect } = data;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    disbursmentStartDate ? `disbursed_date_from=${disbursmentStartDate}` : '',
    disbursmentEndDate ? `disbursed_date_to=${disbursmentEndDate}` : '',
    columnTitle ? `settlement_date=${columnTitle.toLowerCase()}` : '',
    dueStartDate ? `due_date_from=${dueStartDate}` : '',
    dueEndDate ? `due_date_to=${dueEndDate}` : '',
    searchSelect ? `search=${searchSelect}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_statements?${queryString}`
}

export const getClientStatementCSVEndpoint = (data: any) => {
  const { disbursmentStartDate, disbursmentEndDate, columnTitle, dueStartDate, dueEndDate, searchSelect } = data;
  const arr = [
    disbursmentStartDate ? `disbursed_date_from=${disbursmentStartDate}` : '',
    disbursmentEndDate ? `disbursed_date_to=${disbursmentEndDate}` : '',
    columnTitle ? `settlement_date=${columnTitle.toLowerCase()}` : '',
    dueStartDate ? `due_date_from=${dueStartDate}` : '',
    dueEndDate ? `due_date_to=${dueEndDate}` : '',
    searchSelect ? `search=${searchSelect}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_statements?${queryString}`
}

export const getClientStatementPDFEndpoint = (data: any) => {
  const { disbursmentStartDate, disbursmentEndDate, columnTitle, dueStartDate, dueEndDate, searchSelect } = data;
  const arr = [
    disbursmentStartDate ? `disbursed_date_from=${disbursmentStartDate}` : '',
    disbursmentEndDate ? `disbursed_date_to=${disbursmentEndDate}` : '',
    columnTitle ? `settlement_date=${columnTitle.toLowerCase()}` : '',
    dueStartDate ? `due_date_from=${dueStartDate}` : '',
    dueEndDate ? `due_date_to=${dueEndDate}` : '',
    searchSelect ? `search=${searchSelect}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `statement_excel?${queryString}`
}

export const getClientReportEndpoint = (data: any) => {
  const { page, per_page, country, sectorSelection, startDate, endDate, clientSearch } = data;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    country ? `country=${country}` : '',
    sectorSelection?.name ? `sector=${sectorSelection.name}` : '',
    startDate ? `date_from=${startDate}` : '',
    endDate ? `date_to=${endDate}` : '',
    clientSearch?.name ? `client=${clientSearch.name}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `get_client_report?${queryString}`
}

export const getClientReportCsvEndpoint = (data: any) => {
  const { country, sectorSelection, startDate, endDate, clientSearch } = data;
  const arr = [
    country ? `country=${country}` : '',
    sectorSelection.name ? `sector=${sectorSelection.name}` : '',
    startDate ? `date_from=${startDate}` : '',
    endDate ? `date_to=${endDate}` : '',
    clientSearch.name ? `client=${clientSearch.name}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return `client_report_excel?${queryString}`
}



export const handleClientStatementOptionsFilteration = (response: any) => {
  const filterdArray: any[] = []
  if (response.length > 0) {
    response?.forEach((item: any) => {
      const updatedData = {
        id: item?.id,
        name: `${item?.buyer_name} - ${item?.invoice_number ?? item?.purchase_order_no}`,
        number: `${item?.invoice_number ?? item?.purchase_order_no}`
      }
      filterdArray.push(updatedData)
    })
    return filterdArray
  }
  return filterdArray
}

export const handleNewsFilteration = (response: any) => {
  const filteredData: any[] = [];
  const data = response.data;
  if (data && data.length) {
    data?.forEach((item: any) => {
      const updatedData = {
        id: item?.id,
        body: item?.attributes?.body,
        created_at: item?.attributes?.created_at,
        title: item?.attributes?.title,
        image_url: item?.attributes?.images?.[0]?.url,
      };

      const status = item?.attributes?.status;
      if (status === PUBLISHED_NEWS_STATUS) {
        filteredData.push(updatedData);
      }
    });
  }
  return filteredData;
};

export const stripHtmlTags = (html: string) => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  const plainText = temp.innerText;
  return plainText;
};

export const isFileRevied = (fileArray: any) => {
  for (const item of fileArray) {
    if (!item.status) {
      return false;
    }
  }
  return true;
};
export const urlParamsToState = (url: string) => {
  const searchParams = new URLSearchParams(url);
  const state: any = {};
  for (const [key, value] of searchParams) {
    if (key === "search" || key === "sector") {
      state[key] = { id: 1, name: value }
    } else if (key === "page" || key === "per_page") {
      state[key] = Number(value)
    } else {
      state[key] = value;
    }
  }
  return state;
};

export const parseQueryString = (queryString: any) => {
  const searchParams = new URLSearchParams(queryString);
  const state: any = {};
  for (const [key, value] of searchParams) {
    if (key === "client") {
      state["clientSearch"] = { id: "", name: "" }
    } else if (key === "sector") {
      state["sectorSelection"] = { id: "", name: "" }
    } else if (key === "page" || key === "per_page") {
      state[key] = Number(value)
    } else {
      state[key] = value;
    }
  }
  return state;

}

export const stateToQueryParams = (obj: any) => {
  const { page, per_page, clientSearch, sectorSelection, client, sector, date_to, date_from, country, disbursed_date_from, disbursed_date_to, of_days_past_due, factor_due_date_from, factor_due_date_to } = obj;
  const arr = [
    page ? `page=${page}` : '',
    per_page ? `per_page=${per_page}` : '',
    country ? `country=${country}` : '',
    clientSearch.name ? `clientSearch=${clientSearch.name}` : '',
    client.name ? `client=${client.name}` : '',
    sectorSelection.name ? `sectorSelection=${sectorSelection.name}` : '',
    sector.name ? `sector=${sector.name}` : '',
    of_days_past_due ? `of_days_past_due=${of_days_past_due}` : '',
    disbursed_date_to ? `disbursed_date_to=${disbursed_date_to}` : '',
    disbursed_date_from ? `disbursed_date_from=${disbursed_date_from}` : '',
    date_from ? `date_from=${date_from}` : '',
    date_to ? `date_to=${date_to}` : '',
    factor_due_date_from ? `factor_due_date_from=${factor_due_date_from}` : '',
    factor_due_date_to ? `factor_due_date_to=${factor_due_date_to}` : '',
  ]
  const queryString = arr.filter(Boolean).join('&');
  return queryString;
}

export const pathChecker = (url: string) => {
  if (url.includes('/admin/client-reports') || url.includes('/admin/company-reports')) {
    return true;
  }
  return false
}

export const clientPathChecker = (url: string) => {
  if (url.includes('/submit-invoice') || url.includes('/purchase-order')) {
    return true;
  }
  return false
}

export const updatedChartData = (data: any) => {
  const chartData = [
    {
      color: "#FEF8E6",
      name: "Disbursed Factor Amount",
      value: data?.disbursed_factor_amount,
      radius: '10px',
      fillColors: "#000"
    },
    {
      color: "#E5B632",
      name: "Factor Amount Available",
      value: data?.factor_amount_available,
      radius: '100px',
      fillColors: "#fff"
    }
  ];

  return chartData
}

export const updatedChartCardData = (data: any) => {
  const CardMapper = [
    {
      id: 1,
      Title: "Past Due Factor Amount",
      Detail: data?.card_data_1?.past_due_factor_amount || "J$ 0",
      dues: data?.card_data_1?.count_of_past_due_payments
        ? `${data.card_data_1.count_of_past_due_payments} payments past due`
        : "0  payments past due "
    },
    {
      id: 2,
      Title: "Next Factor due in",
      Detail: data?.card_data_2?.sum_days_until_due
        ? `${data.card_data_2.sum_days_until_due} Days`
        : "-",
      dues: data?.card_data_2?.due_amount
        ? `DUE AMOUNT ${data.card_data_2.due_amount}`
        : "DUE AMOUNT J$ - "
    },
    {
      id: 3,
      Title: "Total amount due",
      Detail: data?.card_data_3?.total_amount_due || "J$ -",
      is_paid: data?.card_data_3?.is_paid || false,
      dues: data?.card_data_3?.payments_due_in_next_30_days
        ? `${data.card_data_3.payments_due_in_next_30_days} INVOICES/POs DUE IN NEXT 30 DAYS`
        : "0  INVOICES/POs DUE IN NEXT 30 DAYS"
    }
  ];
  return CardMapper
}


export const totalFactorCalculator = (data: any) => {
  const value = parseFloat(data?.factor_limit?.replace(/[^\d.]/g, ''))
  return value
}

export const currencyFormat = (data: any, isNoSymbol?: boolean) => {
  const cleanedData = data?.replace(/ /g, '').replace(/,/g, '');
  const currencyCode = cleanedData?.substring(0, 2);
  let numericValue = parseFloat(cleanedData?.substring(2));

  if (numericValue < 1000000) {
    return data;
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let formattedValue = numericValue.toFixed(2);
  let suffixIndex = 0;
  while (numericValue >= 1000 && suffixIndex < suffixes.length - 1) {
    numericValue /= 1000;
    formattedValue = numericValue.toFixed(2);
    suffixIndex++;
  }
  const formattedString = isNoSymbol ? `${formattedValue}${suffixes[suffixIndex]}` : `${currencyCode} ${formattedValue}${suffixes[suffixIndex]}`;
  return formattedString;
};

export const timeAgoFormatter = (date: any) => {
  const dateTime = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const now = moment()
  const minutesDifference = now.diff(dateTime, 'minutes');
  if (!dateTime.isValid()) {
    return '-';
  }
  let result = '';
  if (minutesDifference < 1) {
    result = 'Just now';
  } else if (minutesDifference === 1) {
    result = `1 minute ago`;
  } else if (minutesDifference < 60) {
    result = `${minutesDifference} minutes ago`;
  } else if (minutesDifference < 1440) {
    result = 'Today, ' + dateTime.format('hh:mm A');
  } else if (minutesDifference < 2880) {
    result = 'Yesterday, ' + dateTime.format('hh:mm A');
  } else {
    result = dateTime.format('DD MMM, YYYY hh:mm A')
  }
  return result;
}

export const notificationStatusToColor = (status: any) => {
  switch (status) {
    case 'closed':
      return "#20BB6E"
    case 'review_now':
      return "#F27151"
    case 'in_review':
      return "#E5B632";
    default:
      return "#bfbfbf"
  }
}

export const formatCurrencySufWithPrefix = (input: string | number, prefix?: string): string => {
  const number = typeof input === 'string' ? parseFloat(input.replace(/[^\d.]/g, '')) : input;
  if (isNaN(number)) {
    return "0";
  }
  let res: string;
  switch (true) {
    case number < 1e3:
      res = `${number.toFixed(2)}`;
      break;
    case number < 1e6:
      res = (number / 1e3).toFixed(2) + 'K';
      break;
    case number < 1e9:
      res = (number / 1e6).toFixed(2) + 'M';
      break;
    case number < 1e12:
      res = (number / 1e9).toFixed(2) + 'B';
      break;
    default:
      res = (number / 1e12).toFixed(2) + 'T';
  }
  // res = res.replace(".00", '');
  return !!prefix ? `${prefix} ${res}` : res;
}
export const revenueChartData = (data: any) => {
  const modifiedData = data.sector_data.map((item: any, index: number) => {
    const { sector, total_commission, total_commission_percentage } = item;
    const colorIndex = index % coloursArray.length;
    const color = coloursArray[colorIndex];

    return {
      sector,
      value: total_commission_percentage,
      ammount: total_commission,
      percentage: total_commission_percentage,
      total_commission_percentage,
      total_commission,
      color
    };
  });
  return modifiedData;
}


export const globalChartData = (data: any) => {
  const modifiedData = data.sector_data.map((item: any, index: number) => {
    const { sector, total_disbrused, total_disbursed_percentage } = item;
    const colorIndex = index % coloursArray.length;
    const color = coloursArray[colorIndex];
    return {
      sector,
      value: total_disbursed_percentage,
      ammount: total_disbrused,
      percentage: total_disbursed_percentage,
      total_disbursed_percentage,
      total_disbrused,
      color
    };
  });
  return modifiedData;

};

export const handleColorAdder = (data: any) => {
  const modifiedData = data.sector_data.map((item: any, index: number) => {
    const colorIndex = index % coloursArray.length;
    const color = coloursArray[colorIndex];
    return {
      ...item,
      color
    };
  });
  return modifiedData;

};

export const refinedCardsData = (data: any) => {
  const cardData = data.card_data.map((entry: any) => {
    const { title, ammount_value, sub_title_value, precentage } = entry
    return {
      title,
      amount: ammount_value,
      bottomTitle: title === "Factor Credit Due In 5 Days By Clients" ? "" : sub_title_value,
      percentage: title === "Factor Credit Due In 5 Days By Clients" ? "" : precentage
    }
  })
  return cardData
};

export const getLocalUserData = () => {
  const user_json = localStorage.getItem("user");
  const user = user_json !== null ? JSON.parse(user_json) : null;
  return user
}

export const preventClientLoginFlow = (props: any) => {
  const { role, navigation } = props;
  const current_Path = window.location.pathname.toUpperCase();
  const user = getLocalUserData();
  if (role === "client" && user?.kyc_status !== KYC_Status.approve && !ekycPaths.includes(current_Path)) {
    navigation.navigate("EmailLogin")
    localStorage.clear();
  }
}


export const customPDFData = (data: any, settlementDateValue: boolean) => {
  const transformedData: any[] = [];
  const sortedIds: any[] = [];

  data?.statements?.data?.forEach((item: any) => {
    const transformedItem: any = {
      tile1: item?.attributes?.disbursement_date,
      tile2: item?.attributes?.factor_due_date,
      tile3: item?.attributes?.original_amount_disbursed || "",
      tile4: item?.attributes?.original_factor_commission_due,
      tile5: item?.attributes?.delinquent_fee,
      tile6: item?.attributes?.amount_to_be_settled,
      tile7: item?.attributes?.invoice_po,
      tile8: item?.attributes?.invoice_po_amount,
      tile9: item?.attributes?.buyer_name,
      tile10: item?.attributes?.invoice_po_date,
      tile11: item?.attributes?.cci_settement_date || ""
    };
    transformedData.push(transformedItem);

    if (
      item?.attributes?.payments &&
      item?.attributes?.payments.data.length > 0
    ) {
      item?.attributes?.payments.data.forEach((payment: any) => {
        const data: any = {
          tile1: "",
          tile2: "",
          tile3: "",
          tile4: "",
          tile5: "",
          tile6: `( ${payment.attributes?.amount_to_be_settled} )`,
          tile7: "",
          tile8: "",
          tile9: "PAYMENT",
          tile10: "",
          tile11: `${payment.attributes?.payment_date}`,
        };
        transformedData.push(data);
      });
    }
    settlementDateValue && data?.credit_statements?.data.forEach((element: any) => {
      if (element?.attributes?.invoice_po_id == item?.id) {
        const creditReturnData: any = {
          tile1: `${element.attributes?.payment_date}`,
          tile2: "",
          tile3: `${element.attributes?.amount_to_be_settled}`,
          tile4: "",
          tile5: "",
          tile6: "",
          tile7: "",
          tile8: "",
          tile9: "CREDIT RETURN",
          tile10: "",
          tile11: "",
        }
        transformedData.push(creditReturnData);
        sortedIds.push(element?.id)
      }
    })
  });

  settlementDateValue && data?.credit_statements?.data.forEach((element: any) => {
    if (!sortedIds.includes(element?.id)) {
      const creditReturnData: any = {
        tile1: `${element.attributes?.payment_date}`,
        tile2: "",
        tile3: `${element.attributes?.amount_to_be_settled}`,
        tile4: "",
        tile5: "",
        tile6: "",
        tile7: "",
        tile8: "",
        tile9: "CREDIT RETURN",
        tile10: "",
        tile11: "",
      }
      transformedData.push(creditReturnData);
    }
  })

  return transformedData;
};

export const getStatementStatusColor = (status: string): string => {
  switch (status) {
    case "Paid":
      return "#20BB6E";
    case "Overdue":
      return "#F96262";
    case "Pending":
      return "black";
    default:
      return "#202A45";
  }
};

export const getUserRole = () => {
  const role = localStorage.getItem("account_role")
  return role ? role.toLowerCase() : ""
}

export const paymentTypeUpdater = (amount: any, status: any) => {
  if (status) {
    return `( ${amount} )`
  } else {
    return amount
  }
}