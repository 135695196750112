// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  parseDataIntoOptions,
  parseSectorIntoOptions,
  shouldUpdateInvoicePOtable,
} from "../../../components/src/Utils/parseData";
import { apiCall, getInvoiceReportEndpoint , getInvoiceReportCSVEndpoint } from "../../../components/src/Utils/utils";

export const configJSON = require("./config");

export type token = string | null;

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface Option {
  id: number | string | null;
  name: string;
}

interface S {
  token: string | null;
  invoiceTableData: any[];
  isLoadTable: boolean;
  client: Option;
  totalCount: number;
  page: number;
  total_count:number;
  sector: Option;
  isDel: boolean | null;
  markDel: boolean;
  unmarkDel: boolean;
  selectedUserId: number | string | null;
  csvURL: string;
  clientOptions: any[];
  per_page: number;
  of_days_past_due: string;
  isClientsLoad: boolean;
  country: string;
  sectorOptions: any[];
  disbursed_date_from: any;
  disbursed_date_to: any;
  factor_due_date_from:any;
  factor_due_date_to:any;
  dateRangeLabel: string;
}

interface SS {
  id: any;
}

export default class ClientInvoiceController extends BlockComponent<Props, S, SS> {
  getTableDataApi: string = "";
  getSectorApi: string = "";
  getClientsApi: string = "";
  getURLApi: string = "";
  getDelinquentApi: string = "";
  debounce: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      disbursed_date_from: "",
      disbursed_date_to: "",
      factor_due_date_from:"",
      factor_due_date_to:"",
      invoiceTableData: [],
      token: "",
      isLoadTable: false,
      page: 1,
      client: { id: "", name: "" },
      sector: { id: "", name: "" },
      totalCount: 0,
      total_count:0,
      clientOptions: [],
      csvURL: "",
      sectorOptions: [],
      country: "",
      selectedUserId: null,
      of_days_past_due:"",
      isDel: null,
      markDel: false,
      unmarkDel: false,
      per_page: 10,
      isClientsLoad: false,
      dateRangeLabel: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillUnmount(): any {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
  }

  async componentWillMount() {
    const token = localStorage.getItem("auth_token");
    const getState = localStorage.getItem("invoice_table_states");
    const  getParseState = getState !== null && JSON.parse(getState);
    const getPath = window.location.search

    if (token) {
      if(getParseState && getPath.includes("applied")){  
        this.setState({token: token, isLoadTable: true,...getParseState}, () => {
          const endPointSet = getInvoiceReportEndpoint(this.state);
          this.getTableDataApi = apiCall(token, endPointSet, "GET");
        });
      }else {
        this.setState({token: token, isLoadTable: true}, () => {
          const endPoint = getInvoiceReportEndpoint(this.state);
          this.getTableDataApi = apiCall(token, endPoint, "GET");
          localStorage.removeItem("invoice_table_states")
        });
      }
      
      this.getSectorApi = apiCall(token, `industries`, "GET");
      this.getURLApi = apiCall(token, "invoice_report_excel", "GET");
    }
  }

  clearInvoiceFilters = () => {
    this.setState({
      page: 1,
      isLoadTable: true,
      country: "",
      factor_due_date_from: "",
      disbursed_date_from: "",
      client: { id: "", name: "" },
      of_days_past_due: "",
      sector: { id: "", name: "" },
      disbursed_date_to: "",
      per_page: 10,
      factor_due_date_to: "",
    },() => {
      const endPointClear = this.getInvoiceQueryEndPoint()
      this.getTableDataApi = apiCall(this.state.token , endPointClear, "GET");
    })
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.client.name !== this.state.client.name) {
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.getSearchOptions(this.state.client.name);
      }, 400);
    }
    const {
      page,
      per_page,
      of_days_past_due,
      client,
      sector,
      country,
      disbursed_date_from,
      disbursed_date_to,
      factor_due_date_from,
      factor_due_date_to,
    } = this.state;
    const localState = {
      page,
      per_page,
      of_days_past_due,
      client,
      sector,
      country,
      disbursed_date_from,
      disbursed_date_to,
      factor_due_date_from,
      factor_due_date_to,
    }
    localStorage.setItem("invoice_table_states", JSON.stringify(localState));
    const isUpdate = shouldUpdateInvoicePOtable(prevState, this.state)
    if(isUpdate){
      this.setState({ isLoadTable: true });
      const endPoint1 = this.getInvoiceQueryEndPoint()
      const invoiceCSVDownloadEndpoint = getInvoiceReportCSVEndpoint(this.state)
      this.getTableDataApi = apiCall(this.state.token , endPoint1, "GET");
      this.getURLApi = apiCall(this.state.token, invoiceCSVDownloadEndpoint , "GET");
    }
  }

  getInvoiceQueryEndPoint = () => {
    const res = getInvoiceReportEndpoint(this.state);
    const invUrl = `${window.location.pathname}?applied`;
    window.history.replaceState(null, '', invUrl);
    return res;
  }

  handleLineClick = (row: any) => {
    if (row.order_type === "purchase_order") {
      this.props.navigation.navigate("AdminPOReviewBalance", { id: row.id });
    } else if (row.order_type === "invoice_order") {
      this.props.navigation.navigate("AdminInvoiceReviewBalance", {
        id: row.id,
      });
    }
  };

  handleClientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    this.setState({ client: { id: 0, name: inputValue } });
    if (inputValue === "") {
      this.setState({ clientOptions: [] });
    }
  };

  handleSelectClient = (event: any, client: any) => {
    if (client.id) {
      this.setState({
        client: client,
        page: 1,
        per_page: 10,
        isLoadTable: true,
        country: "",
        disbursed_date_from: "",
        disbursed_date_to: "",
        factor_due_date_from: "",
        factor_due_date_to: "",
      }, () => {
        const endPoint2 = this.getInvoiceQueryEndPoint()
        this.getTableDataApi = apiCall(this.state.token , endPoint2, "GET");
      });
    } else {
      this.setState({ client: { id: 0, name: "" } });
    }
  };
  
  handleChangeReportPage = (e: any, updatedPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: updatedPage,
    });  
  };

  handleSector = (e: any) => {
    const option = this.state.sectorOptions.filter(
      (opt) => opt.id == e.target.value
    );
    if (option.length > 0) {
      this.setState({ sector: option[0] });
    }
  };

  handleInvoiceReportFilter = (col: any, opt: any) => {
    if (col === "of_days_past_due") {
      if (this.state.of_days_past_due === opt) {
        this.setState({
          of_days_past_due: "",
        });
      } else {
        this.setState({
          of_days_past_due: opt,
        });
      }
    } else if (col === "country") {
      if (this.state.country === opt) {
        this.setState({
          country: "",
        });
      } else {
        this.setState({
          country: opt,
        });
      }
    }
  };

  handleDelinquentValue = (value: boolean, id: number | string) => {
    this.setState({ isDel : value, selectedUserId: id})
    if(value === true) {
      this.setState({ unmarkDel: true, markDel: false })
    } else {
      this.setState({ markDel: true, unmarkDel: false })
    }
  }

  handleDelClose = () => {
    this.setState({ unmarkDel: false, markDel: false })
  }

  handleApproveDel = () => {
    const { isDel, selectedUserId, token} = this.state
    if (isDel) {
      this.getDelinquentApi = apiCall(token , `mark_delinquent_fee?account_id=${selectedUserId}&is_delinquent_enable=false` , "PUT")
    }else {
      this.getDelinquentApi = apiCall(token , `mark_delinquent_fee?account_id=${selectedUserId}&is_delinquent_enable=true` , "PUT")
    }
  }

  handleDateRangeFilter = (label:string, date1:any, date2:any) => {
    this.setState({dateRangeLabel: label})
    if(label === "Disbursement Date") {
      this.setState({ disbursed_date_from: date1, disbursed_date_to: date2})
    }else if (label === "Factor Due Date") {
      this.setState({ factor_due_date_from: date1, factor_due_date_to: date2})
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.getResponseApis(apiRequestCallId, responseJson)
      } else {
        this.setState({ isLoadTable: false, isClientsLoad: false });
      }
    }
  }

  getResponseApis = (id: any, res:any) => {
    if (id === this.getTableDataApi) {
      this.getTableDataResponse(res);
    }
    if (id === this.getClientsApi) {
      this.getClientOptionsRes(res);
    }
    if (id === this.getSectorApi) {
      this.getSectorOptionsRes(res);
    }
    if (id === this.getURLApi) {
      res?.url && this.setState({ csvURL: res.url});
    }
    if (id === this.getDelinquentApi) {
      this.handleDelClose();
      this.setState({ isLoadTable: true });
      const endPoint3 = this.getInvoiceQueryEndPoint()
      this.getTableDataApi = apiCall(this.state.token , endPoint3, "GET");
    }
  }

  getClientOptionsRes = (res: any) => {
    if (res.data) {
      const parsedOpt = parseDataIntoOptions(res.data);
      this.setState({ clientOptions: parsedOpt });
    } else {
      this.setState({ clientOptions: [] });
    }
    this.setState({ isClientsLoad: false });
  };

  getSectorOptionsRes = (resp: any) => {
    if (resp.data) {
      const parsedData = parseSectorIntoOptions(resp.data);
      this.setState({
        sectorOptions: parsedData,
      });
    } else {
      this.setState({ sectorOptions: [] });
    }
  };

  getTableDataResponse = (res: any) => {
    if (res?.reports?.data && res?.total_count) {
      const total_count = res.sectors?.total_count || res.total_count || 0; 
      const ceil2 = Math.ceil(res.total_count / this.state.per_page || 1);
      this.setState({
        invoiceTableData: res.reports.data,
        isLoadTable: false,
        totalCount: ceil2,
        total_count:total_count
      });
    } else {
      this.setState({
        invoiceTableData: [],
        totalCount: 0,
        isLoadTable: false,
      });
    }
  };

  getSearchOptions = (value: any) => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientsApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isClientsLoad: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_advance_search?search=${value}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End