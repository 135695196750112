import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, MenuItem, Select, Popper, Paper } from "@material-ui/core";
import VelocityReportsController, { Props } from "./VelocityReportsController.web";
import { styles } from "./ClientReportStyles.web";
//@ts-ignore
import ClientReportTable from "../../../components/src/Table/ClientReportsTable";
import { VelocityTableColumn } from "../../../components/src/Utils/dummyData";
import TwoDateSelector from "../../../components/src/CustomDateSelector/TwoDateSelector.web"
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import { printListItemsMsg, downloadDocs } from "../../../components/src/Utils/utils";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
const icons = require('./assets')

export class VelocityReports extends VelocityReportsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const menuSectorProps1: any = {
            transformOrigin: {
                vertical: "top",
                horizontal: "left",
            },
            classes: { paper: classes?.sectorMenuPropsClass },
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
        };

        const { sectorOptionsResponse, sectorSelection, mainPeriodStartDate, sort_direction, mainPeriodEndDate, comparisonPeriodStartDate, comparisonPeriodEndDate, isMainPeriodFilterOpen, isComparisonPeriodFilterOpen, isPopperOpen1, anchorEl, isPopperOpen, velocityData, page, per_page, count,total_count, isLoading, VelocityExcelURL } = this.state
        const extraDates = {
            startDate : mainPeriodStartDate, 
            endDate : mainPeriodEndDate
        }
        return (
            <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} id="4342" className={classes.responsiveProvider}>
                <Grid container>
                    <Grid container spacing={2} style={{marginBottom:'15px'}}>
                        <Grid item md={12} lg={8} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item md={4} lg={5} xs={12} sm={12}>
                                    <Box className={classes?.sectorBox} id="1">
                                        <Select
                                            id="sectorId1"
                                            className={classes?.sectorSelectField}
                                            value={Number(sectorSelection.id)}
                                            variant="outlined"
                                            data-test-id="select-sector"
                                            onChange={(event) => this.handleSector(event)}
                                            placeholder={"limited_liability"}
                                            MenuProps={menuSectorProps1}
                                            fullWidth
                                        >
                                            <MenuItem
                                                style={{ display: "none" }}
                                                disabled
                                                value={0}
                                            >
                                                Sector
                                            </MenuItem>
                                            {sectorOptionsResponse.length && sectorOptionsResponse.map((item: any, index: number) => (
                                                <MenuItem value={Number(item.id)} key={`${item.id + index}`}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item md={8} lg={7} xs={12} sm={12}>
                                    <Box className={classes.DateSelectorPositionProvider}>
                                        <Box id="2" className={classes?.dateViewerContainer}>
                                            Main Period
                                            <Box className={classes?.dateViewerBorder} id="2fsd" onClick={this.handleMainPeriodFilterCall}>
                                                {mainPeriodStartDate} - {mainPeriodEndDate}
                                                <img className={classes?.dateViewerIcon} src={icons.customCalenderImage} alt="customCalenderImage" />
                                            </Box>
                                            {
                                                isMainPeriodFilterOpen &&
                                                <Popper
                                                    open={isPopperOpen}
                                                    anchorEl={anchorEl}
                                                    style={{ zIndex: 100 }}
                                                    placement="top"
                                                    disablePortal
                                                    transition
                                                    className="popperHolder"
                                                >
                                                    <Paper>
                                                        <TwoDateSelector handleClose={this.handleClose} applyFunction={this.handleMainPeriodFilter} startDate={mainPeriodStartDate} endDate={mainPeriodEndDate} isCurrentrequired={false} />
                                                    </Paper>
                                                </Popper>
                                            }
                                        </Box>
                                        <Box className={classes?.borderProvider} id="bchjsbdjhf"></Box>
                                        <Box id="mkldvcsn78" className={classes?.dateViewerContainer}>
                                            Comparison Period
                                            <Box className={classes?.dateViewerBorder} onClick={this.handleComparisonPeriodFilterCall} id="nkcsdkj765">
                                                {comparisonPeriodStartDate} - {comparisonPeriodEndDate}
                                                <img className={classes?.dateViewerIcon} src={icons.customCalenderImage} alt="abc" />
                                            </Box>
                                            {
                                                isComparisonPeriodFilterOpen &&
                                                <Popper
                                                    transition
                                                    placement="top"
                                                    disablePortal
                                                    open={isPopperOpen1}
                                                    className="popperHolder"
                                                    anchorEl={anchorEl}
                                                    style={{ zIndex: 100 }}
                                                >
                                                    <Paper>
                                                        <TwoDateSelector handleClose={this.handleClose} applyFunction={this.handleComparisonPeriodFilter} startDate={comparisonPeriodStartDate} endDate={comparisonPeriodEndDate} isPreviousRequired={true} isCustomRequired={false} isCurrentrequired={false} extraDates={extraDates} />
                                                    </Paper>
                                                </Popper>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container md={12} lg={4} sm={12} xs={12} className={classes.DownloadPositionProvider}>
                            <CommonButtonWeb btnText="Clear Filters" data-test-id="clear-velocity-filters" classNames={classes.clearClientFilter} handleClick={this.clearVelocityFilters} />
                            <img data-test-id="img-download" onClick={() => downloadDocs(VelocityExcelURL)} alt="" src={icons.downloadExcel} style={{ cursor: "pointer" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Box id="ididid" >
                    <ClientReportTable
                        isThreeDotsMenuNotRequired={true}
                        sort_direction={sort_direction}
                        data={velocityData}
                        loadingTableData={isLoading}
                        extraClassnames={classes?.tableClass}
                        handleSort={this.handleSort}
                        headerClasses={classes?.tableHeaderClasses}
                        columns={VelocityTableColumn}
                    />
                </Box>
                <Box id="nkcjsd878623" sx={{ marginTop: '35px' }}>
                    <CustomPagination
                        page={page}
                        onPaginationChange={this.handleChangePage}
                        count={count}
                        msg={printListItemsMsg(count, page, per_page,total_count)}
                    />
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles)(VelocityReports);
