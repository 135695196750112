// Customizable Area Start
import * as Yup from "yup";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { FormikErrors, FormikTouched } from "formik";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export type DocumentsArray = { [x: string]: any }[];

export type token = string | null;

export interface Fields {
  buyer_name: string | number;
  invoice_amount: string | number;
  invoice_number: string | number;
  payment_terms: number | null;
  invoice_date: string;
  disbursement_date: string | null;
  amount_disbursed: string | number;
}

interface S {
  token: token;
  rejectOpen1: boolean;
  approveOpen1: boolean;
  comment1: string;
  factor_limit: number;
  isBalancetable: boolean;
  attributes: any;
  requestId: number;
  account_id: number | null;
  activeStep: number;
  isLoading1: boolean;
  invoiceDocuments: DocumentsArray;
  companyName1: string;
  createdAtDate1: string;
  fields: Fields;
}

interface SS {
  id: any;
}

export default class InvoiceReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getInvoiceDetailsAPI: string = "";
  invoiceVerificationAPI: string = "";
  updateInvoiceDocuments: string = "";
  updateDisbursementAPI: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      token: "",
      rejectOpen1: false,
      approveOpen1: false,
      comment1: "",
      requestId: 0,
      activeStep: 0,
      isBalancetable: false,
      account_id: null,
      isLoading1: false,
      invoiceDocuments: [],
      factor_limit: 0,
      companyName1: "",
      attributes: {},
      createdAtDate1: "",
      fields: {
        buyer_name: "",
        invoice_amount: "",
        invoice_number: "",
        invoice_date: "",
        payment_terms: 0,
        disbursement_date: "",
        amount_disbursed: "",
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillMount(): any {
    if (localStorage.getItem("auth_token")) {
      const path: any[] = window.location.pathname.split("/");
      const token = localStorage.getItem("auth_token");
      const id = path[path.length - 1];

      this.setState({
        token: token,
        requestId: Number(id),
        isLoading1: true,
      });
      this.getInvoiceDetailsAPI = this.apiCallInvoice(
        token,
        `bx_block_invoicebilling/invoice_details/${id}`,
        "GET"
      );
    } else {
      this.props.navigation.navigate("EmailLogin");
    }
  }

  showNoteOnCompDate = (disbursement_date: string, payment_terms: number) => {
    if (disbursement_date) {
      let disbursementDate = new Date(disbursement_date);
      disbursementDate.setDate(disbursementDate.getDate() + payment_terms);
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate()-1);
      return disbursementDate > currentDate;
    }
    return true; 
  }


  handleInvoiceErrors = (error: any, touch: any) => {
    return touch && Boolean(error);
  };

  handleInvoiceHelperText = (text: any, touch: any) => {
    return touch && text;
  };

  handleInvoiceHelperText2 = (text: any) => {
    if (text === undefined) {
      const error = "Error : Amount disbursed can not be greater than the invoice amount ."
      return error
    }
    return `Error : ${text}`
  };

  handleInvoiceDocumentsResponse = (response: any) => {
    const companyName1 = response.data.attributes.companyname;
    const createdDate = response.data.attributes.created_at;
    const {
      documents,
      buyer_name,
      invoice_number,
      invoice_amount,
      invoice_date,
      payment_terms,
      account_id,
      status,
      disbursement_date,
      factor_limit,
      original_amount_disbursed
    } = response.data.attributes;
    const object = {
      buyer_name: buyer_name,
      invoice_number: invoice_number,
      invoice_amount: invoice_amount,
      invoice_date: invoice_date,
      payment_terms: payment_terms,
      disbursement_date: disbursement_date,
      amount_disbursed: original_amount_disbursed,
    };
    const path2: any[] = window.location.pathname.split("/");
    if (path2.includes("client-reports") && status === "disbursed") {
      this.setState({ isBalancetable: true })
    }
    this.setState({
      invoiceDocuments: documents,
      fields: object,
      isLoading1: false,
      account_id: account_id,
      attributes: response.data.attributes,
      companyName1: companyName1,
      createdAtDate1: createdDate,
      factor_limit: factor_limit ?? 0,
    });
    if (status === "approve") {
      this.setState({
        activeStep: 2,
      });
    } else if (status === "disbursed") {
      this.setState({
        activeStep: 3,
      });
    } else {
      this.setState({
        activeStep: 0,
      });
    }
  };

  handleInvoiceReview = (id: any) => {
    const { token, requestId } = this.state;
    this.updateInvoiceDocuments = this.apiCallInvoice(
      token,
      `update_attachment_status?attachment_id=${id}&status=reviewed&invoice_detail_id=${requestId}`,
      "PUT"
    );
    this.setState({ isLoading1: true });
  };

  getPaymentTermsHelperText = (
    values: { payment_terms: number | null },
    errors: FormikErrors<{ payment_terms: number | null }>,
    touched: FormikTouched<{ payment_terms: number | null }>,
    handleInvoiceHelperText: { (text: string | undefined, touch: boolean | undefined): string | undefined; (arg0: string | undefined, arg1: boolean | undefined): string | undefined; }
  ) => {
    if (values.payment_terms && values.payment_terms < 30) {
      return "Payment terms cannot be less than 30 days";
    }
    return handleInvoiceHelperText(errors.payment_terms, touched.payment_terms);
  };
  updateDisbursement = (values: any) => {
    this.setState({isLoading1: true})
    const { token } = this.state;

    this.updateDisbursementAPI = this.apiCallInvoice(
      token,
      "update_disbursement",
      "PUT",
      JSON.stringify(values)
    );
  };


  handleDocumentStatusUpdate = () => {
    const { token, requestId } = this.state;
    this.getInvoiceDetailsAPI = this.apiCallInvoice(
      token,
      `bx_block_invoicebilling/invoice_details/${requestId}`,
      "GET"
    );
  };

  handleModalCloseInvoice = () => {
    this.setState({ rejectOpen1: false, approveOpen1: false });
  };

  handleRejectInputChangeInvoice = (e: any) => {
    const { value, name } = e.target;
    const newState: any = {
      [name + `1`]: value,
    };
    this.setState(newState);
  };

  rejectRequestInvoice = () => {
    const { token, requestId, comment1, account_id } = this.state;

    const data = {
      account_id: account_id,
      invoice_detail_id: requestId,
      status: "rejected",
      comment: comment1,
    };

    this.invoiceVerificationAPI = this.apiCallInvoice(
      token,
      "approve_reject_invoice",
      "POST",
      JSON.stringify(data)
    );
    this.setState({ rejectOpen1: false, isLoading1: true });
  };

  handleApproveClickInvoice = () => {
    const { token, requestId, account_id } = this.state;
    const data = {
      invoice_detail_id: requestId,
      account_id: account_id,
      status: "approve",
    };
    this.invoiceVerificationAPI = this.apiCallInvoice(
      token,
      "approve_reject_invoice",
      "POST",
      JSON.stringify(data)
    );
    this.setState({
      isLoading1: true,
      approveOpen1: false,
    });
  };

  handleRejectClickInvoice = () => {
    this.setState({ rejectOpen1: true });
  };

  hadleResInvoiceVerificationAPI = (response: any) => {
    if (response.data) {
      this.setState({
        approveOpen1: false,
        rejectOpen1: false,
        isLoading1: false,
      });
      let com = this.state.isBalancetable ? "ActiveTabs" : "AdminNotifications"
      this.props.navigation.navigate(com);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getInvoiceDetailsAPI) {
      this.handleInvoiceDocumentsResponse(responseJson);
    }
    if (
      apiRequestCallId === this.invoiceVerificationAPI ||
      apiRequestCallId === this.updateDisbursementAPI
    ) {
      this.hadleResInvoiceVerificationAPI(responseJson);
    }
    if (apiRequestCallId === this.updateInvoiceDocuments) {
      this.handleDocumentStatusUpdate();
    }
  };

  apiCallInvoice = (
    token: token,
    endPoint: string,
    method: string,
    body?: any
  ) => {
    const bodyHeader1 = {
      token: token,
      "Content-Type": "application/json",
    };

    const header1 = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(bodyHeader1)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header1)
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
}
// Customizable Area End
