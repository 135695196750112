// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NotificationSettingsController, {
  Props,
} from "./NotificationSettingsController.web";
import { styles } from "./NotificationSettingsStyles.web";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import {
  notificationColumns,
} from "../../../components/src/Utils/dummyData";
import { printListItemsMsg } from "../../../components/src/Utils/utils";
import { parseTableData } from "../../../components/src/Utils/parseData";
import AdvanceSearchWeb from "../../../components/src/Search/AdvanceSearch.web";
import CommonButton from "../../../components/src/Button/CommonButton.web";

class NotificationSettings extends NotificationSettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      page,
      count,
      per_page,
      total_count,
      sort_direction,
      document_title,
      review_status,
      notificationData,
      isLoading,
      searchOptions,
      loadingSearch,
    } = this.state;

    const data = parseTableData(notificationData)
   
    return (
      // Required for all blocks
      <div className={classes.mainDiv}>
        <h5 className="pageTitle">Notifications</h5>
        <Grid container spacing={2} className="searchContainer">
          <Grid item lg={8} md={8} xs={12}>
            <AdvanceSearchWeb
              searchValue={this.state.search}
              handleInputChange={this.handleInputChange}
              handleSelectSearch={this.handleSelectSearch}
              handleSearchClick={this.handleSearch}
              options={searchOptions}
              loading={loadingSearch}
              isSearchIcon={true}
              width="80%"
            />
          </Grid>
          <Grid item lg={4} md={4}>
            <CommonButton btnText="Clear Filters" data-test-id="clear-table-filters" classNames="clearTableFilter" handleClick={this.clearNotificationFilter}/>
          </Grid>
        </Grid>
        <Grid className="tableContainer">
          <CommonTable
            data-test-id="notification-table"
            columns={notificationColumns}
            data={data}
            headerClasses={classes.notificationHeaderClasses}
            handleSort={this.handleSort}
            handleFilter={this.handleFilter}
            sort_direction={sort_direction}
            filter={[document_title, review_status]}
            loadingTableData={isLoading}
            navigate={this.props.navigation.navigate}
            handleRowClick={this.handleNotificationRowClick}
            extraClasses={classes.bodyClasses}
          />
        </Grid>
        <Grid>
          <CustomPagination
            count={count}
            page={page}
            onPaginationChange={this.handleChangePage}
            msg={printListItemsMsg(count, page, per_page,total_count)}
            classes={classes.customPaginationClass}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NotificationSettings);
// Customizable Area End
