import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, MenuItem ,Select} from "@material-ui/core";
import ClientReportController, {
    Props,
} from "./ClientReportController.web";
import { styles } from "./ClientReportStyles.web";
//@ts-ignore
import DelinquentModal from "../../../components/src/Modal/DelinquentModal.web";
//@ts-ignore
import ClientReportTable from "../../../components/src/Table/ClientReportsTable";
import { ClientReportColumns } from "../../../components/src/Utils/dummyData";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import AdvanceSearch from '../../../components/src/Search/AdvanceSearch.web'
import { printListItemsMsg , downloadDocs } from "../../../components/src/Utils/utils";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
const icons = require('./assets')

export class ClientReport extends ClientReportController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const menuSectorProps: any = {
            classes: { paper: classes.sectorMenuPropsClass },
            transformOrigin: {
                vertical: "top",
                horizontal: "left",
            },
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
        };
        const { CSVFileURL,page, count, per_page,total_count, anchorEl, selectedClient, clientData, isUnMarkModalOpen, isMarkModalOpen, isLoading, country, clientSearch, sectorSelection, isClientsLoad1, clientOptionsResponse, sectorOptionsResponse ,startDate,endDate } = this.state
        return (
            <>
                <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider}>
                    <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box display="flex" style={{ gap: "20px" }}>
                                <AdvanceSearch
                                    searchValue={clientSearch}
                                    handleInputChange={this.handleClientInput}
                                    handleSelectSearch={this.handleSelectClient}
                                    options={clientOptionsResponse}
                                    width="45%"
                                    placeHolder="Client"
                                    loading={isClientsLoad1}
                                    classNames={classes.clientSearchCustomClass}
                                />
                                <Box className={classes.sectorBox} style={{width:'45%'}}>
                                    <Select
                                        id="sectorId"
                                        value={Number(sectorSelection.id)}
                                        variant="outlined"
                                        data-test-id="select-sector"
                                        onChange={(event) => this.handleSector(event)}
                                        placeholder={"limited_liability"}
                                        className={classes.sectorSelectField}
                                        MenuProps={menuSectorProps}
                                        fullWidth
                                    >
                                        <MenuItem
                                            style={{ display: "none" }}
                                            disabled
                                            value={0}
                                        >
                                            Sector
                                        </MenuItem>
                                        {!!sectorOptionsResponse.length && sectorOptionsResponse.map((item: any, index: number) => (
                                            <MenuItem value={Number(item.id)} key={`${item.id + index}`}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box display="flex" style={{ gap: "20px", alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                <CommonButtonWeb btnText="Clear Filters" data-test-id="clear-client-filters" classNames={classes.clearClientFilter} handleClick={this.clearClientFilters} />
                                <img data-test-id="img-download" onClick={() => downloadDocs(CSVFileURL)} alt="" src={icons.downloadExcel} style={{ cursor: "pointer" }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box>
                        <ClientReportTable
                            columns={ClientReportColumns}
                            data={clientData}
                            headerClasses={classes.tableHeaderClasses}
                            loadingTableData={isLoading}
                            handleMenuOpen={this.handleMenuOpen}
                            handleMenuClose={this.handleMenuClose}
                            anchorEl={anchorEl}
                            handleMenuItemClick={this.handleMenuItemClick}
                            selectedClient={selectedClient}
                            handleFilter={this.handleFilter}
                            filter={[country]}
                            handleDateChanged={this.handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </Box>
                    <Box sx={{ marginTop: '35px' }}>
                        <CustomPagination
                            count={count}
                            page={page}
                            onPaginationChange={this.handleChangePage}
                            msg={printListItemsMsg(count, page, per_page,total_count)}
                        />
                    </Box>
                </Box>
                <DelinquentModal
                    open={isMarkModalOpen}
                    title="Are you sure ?"
                    description="Marking this as a delinquent adds 1% commission per week on the Outstanding Account Balance"
                    handleModelClose={this.handleModalClose}
                    handleApproveModal={this.handleApprove}
                    widthToAdd='85%'
                />
                <DelinquentModal
                    open={isUnMarkModalOpen}
                    title="Are you sure ?"
                    description="This stops the 1% delinquent fee addition to the Outstanding Account Balance"
                    handleModelClose={this.handleModalClose}
                    handleApproveModal={this.handleApprove}
                    widthToAdd='85%'
                />
            </>
        );
    }
}

export default withStyles(styles)(ClientReport);
