// Customizable Area Start
import React from "react";
import { Grid, Box, Select, MenuItem, withStyles } from "@material-ui/core";
import ClientInvoiceController, { Props } from "./ClientInvoiceController.web";
import { downloadExcel } from "./assets";
import { styles } from "./ClientInvoiceStyles.web";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import { invoicePOColumns } from "../../../components/src/Utils/dummyData";
import { parseTableData } from "../../../components/src/Utils/parseData";
import AdvanceSearch from "../../../components/src/Search/AdvanceSearch.web";
import { downloadDocs, invoicePoDateRange, printListItemsMsg } from "../../../components/src/Utils/utils";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import DelinquentModal from "../../../components/src/Modal/DelinquentModal.web";
import CommonButton from "../../../components/src/Button/CommonButton.web";

class ClientInvoice extends ClientInvoiceController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {
      invoiceTableData,
      isLoadTable,
      clientOptions,
      client,
      page,
      per_page,
      total_count,
      totalCount,
      csvURL,
      markDel,
      unmarkDel,
      isClientsLoad,
      sectorOptions,
      sector,
    } = this.state;

    const menuSectorProps: any = {
      classes: { paper: classes.menuSectorPaper },
      transformOrigin: {
        horizontal: "left",
        vertical: "top",
      },
      getContentAnchorEl: null,
      anchorOrigin: {
        horizontal: "left",
        vertical: "bottom",
      },
    };

    let data = parseTableData(invoiceTableData);
    const filter = invoicePoDateRange(this.state);

    return (
      // Required for all blocks
      <Box sx={{ padding: "0 32px 32px", width: "calc(100vw - 285px)" }} className={classes.responsiveProvider}>
        <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AdvanceSearch
                  searchValue={client}
                  handleInputChange={this.handleClientInput}
                  handleSelectSearch={this.handleSelectClient}
                  options={clientOptions}
                  placeHolder="Client"
                  loading={isClientsLoad}
                  width="100%"
                  classNames={classes.clientSearch}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className={classes.sectorContainer}>
                  <Select
                    id="sectorId"
                    variant="outlined"
                    value={Number(sector.id)}
                    data-test-id="select-sector"
                    onChange={(e) => this.handleSector(e)}
                    className={classes.selectFieldSector}
                    style={{ width: "100%" }}
                    MenuProps={menuSectorProps}
                  >
                    <MenuItem value={0} disabled style={{ display: "none" }}>Sector</MenuItem>
                    {!!sectorOptions.length &&
                      sectorOptions.map((o: any, index) => (
                        <MenuItem value={Number(o.id)} key={`${o.id + index}`}>
                          {o.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="flex-end"
              gridColumnGap="8px"
              justifyContent="flex-end"
            >
              <CommonButton btnText="Clear Filters" data-test-id="clear-table-filters" classNames={classes.clearTableFilter} handleClick={this.clearInvoiceFilters} />
              <img src={downloadExcel} data-test-id="download-csv" onClick={() => downloadDocs(csvURL)} style={{ cursor: "pointer" }} />
            </Box>
          </Grid>
        </Grid>
        <Grid>
          <CommonTable
            columns={invoicePOColumns}
            data={data}
            headerClasses={classes.headerInvoicePOClasses}
            loadingTableData={isLoadTable}
            navigate={this.props.navigation.navigate}
            handleRowClick={this.handleLineClick}
            handleFilter={this.handleInvoiceReportFilter}
            handleDelinquentValue={this.handleDelinquentValue}
            handleDateRangeFilter={this.handleDateRangeFilter}
            isFirstVertical={true}
            isMoreVert={true}
            filter={filter}
            navigation={this.props.navigation}
            isThreeDotsMenuNotRequired={true}
          />
        </Grid>
        <Box sx={{ marginTop: '35px' }}>
          <CustomPagination
            count={totalCount}
            page={page}
            onPaginationChange={this.handleChangeReportPage}
            msg={printListItemsMsg(totalCount, page, per_page,total_count)}
          />
        </Box>
        <DelinquentModal
          open={markDel}
          title="Are you sure ?"
          handleApproveModal={this.handleApproveDel}
          description="Marking this as a delinquent adds 1% commission per week on the Outstanding Account Balance"
          handleModelClose={this.handleDelClose}
          widthToAdd='85%'
        />
        <DelinquentModal
          open={unmarkDel}
          title="Are you sure ?"
          handleApproveModal={this.handleApproveDel}
          widthToAdd='85%'
          description="This stops the 1% delinquent fee addition to the Outstanding Account Balance"
          handleModelClose={this.handleDelClose}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(ClientInvoice);
// Customizable Area End