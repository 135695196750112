// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ActivityLogController, { Props } from "./ActivityLogController.web";
import { styles } from "./ActivityLogStyles.web";
import { downloadExcel, downloadPdf } from "./assets";
import CommonTable from "../../../components/src/Table/CommonTable.web";
import CustomPagination from "../../../components/src/Pagination/CustomPagination.web";
import { activityLogColumns } from "../../../components/src/Utils/dummyData";
import { parseTableData } from "../../../components/src/Utils/parseData";
import { printListItemsMsg } from "../../../components/src/Utils/utils";
import AdvanceSearchWeb from "../../../components/src/Search/AdvanceSearch.web";
import CommonButton from "../../../components/src/Button/CommonButton.web";

class ActivityLog extends ActivityLogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { page, count, per_page,total_count, sort_direction, role, loadingTableData, isLoadingOptions, searchLogOptions} = this.state;

    const data = parseTableData(this.state.activityData);
    return (
      // Required for all blocks
      <div className={classes.mainDiv}>
        <h5 className="pageTitle">Activity Log</h5>
        <Grid container spacing={2} className="searchContainer">
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <AdvanceSearchWeb
              searchValue={this.state.searchValue}
              handleInputChange={this.handleInputChangeActivity}
              handleSelectSearch={this.handleSelectSearchActivity}
              handleSearchClick={this.handleSearchActivity}
              options={searchLogOptions}
              loading={isLoadingOptions}
              isSearchIcon={true}
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <div className={classes.downloadContainer}>
              <CommonButton btnText="Clear Filters" data-test-id="clear-activity-filters" classNames="clearActTableFilter" handleClick={this.clearActivityFilters} />
              <img
                src={downloadExcel}
                onClick={() => this.handleDownload("excel")}
              />
              <img src={downloadPdf} onClick={() => this.handleDownload("pdf")} />
            </div>
          </Grid>
        </Grid>
        <Grid className="tableContainer">
          <CommonTable
            columns={activityLogColumns}
            data={data}
            headerClasses={classes.headerClasses}
            handleSort={this.handleSort}
            handleFilter={this.handleFilter}
            sort_direction={sort_direction}
            filter={[role]}
            loadingTableData={loadingTableData}
          />
        </Grid>
        <Grid>
          <CustomPagination
            count={count}
            page={page}
            onPaginationChange={this.handleChangePage}
            msg={printListItemsMsg(count, page, per_page,total_count)}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ActivityLog);
// Customizable Area End
