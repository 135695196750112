import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { handleClientFilteration , handleIndustryFilter , handleClientOptionFilter , getClientReportEndpoint , getClientReportCsvEndpoint } from "../../../components/src/Utils/utils"
import { shouldClientReportTableUpdate  } from "../../../components/src/Utils/parseData";


export interface Props {
  classes: any;
  navigation: any;
}

interface ClientSearch {
  id : string | number;
  name: string
}

interface Client {
    id: number;
    declaration_name: string;
    is_delinquent_enable: boolean;
    company_name: string;
}

type token = string | null

interface S {
    token : token
    anchorEl: null | HTMLElement;
    selectedClient: Client | null;
    clientData: Client[];
    isMarkModalOpen: boolean;
    isUnMarkModalOpen: boolean;
    singleClientId : string;
    isDelinquent: boolean;
    isLoading:boolean;
    page: number;
    per_page: number;
    total_count:number;
    sectorSelection:ClientSearch;
    clientSearch:ClientSearch;
    country:any;
    count:number;
    isClientsLoad1:boolean;
    clientOptionsResponse:any;
    sectorOptionsResponse:any;
    CSVFileURL:string;
    startDate:any;
    endDate:any;
}

interface SS {
  id: any;
}

export default class ClientReportController extends BlockComponent<Props, S, SS> {
  getClientDataAPI : string = "";
  unMarkDelinquentAPI : string = "";
  markDelinquentAPI : string = "";
  getClientSectorsAPI : string = "";
  getClientOptionsAPI : string = "";
  debounceTimeOutFunction: any = null;
  getDocDownloadAPI : string = "";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      token:'',
      anchorEl: null,
      selectedClient: null,
      clientData : [],
      isMarkModalOpen: false,
      isUnMarkModalOpen: false,
      singleClientId:'',
      isDelinquent:false,
      isLoading:false,
      page: 1,
      count:0,
      sectorSelection:{
        id:"",
        name:""
      },
      per_page: 10,
      clientSearch:{
        id:"",
        name:""
      },
      total_count:0,
      country:"",
      isClientsLoad1:false,
      clientOptionsResponse:[],
      sectorOptionsResponse:[],
      CSVFileURL:'',
      startDate:"",
      endDate:"",
    };
  }

  async componentWillMount() {
    const getStateObj = localStorage.getItem("client_table_states");
    const getUrl = window.location.search;
    const  getParseObj = getStateObj !== null && JSON.parse(getStateObj);

    if (!localStorage.getItem("auth_token")) {
      this.props.navigation.navigate("EmailLogin");
    } else {
      const token = localStorage.getItem("auth_token");
      if(getParseObj && getUrl.includes("applied")){
        this.setState({ token:token , isLoading:true, ...getParseObj }, () => { 
          const reportEndpoint = getClientReportEndpoint(getParseObj)
          this.getClientDataAPI = this.clientApiCall(token, reportEndpoint , "GET") 
        });
      } else {
        this.setState({ token:token , isLoading:true }, () => {
          this.getClientDataAPI = this.clientApiCall(token, `get_client_report` , "GET")
          localStorage.removeItem("client_table_states")
        });
      }
      this.getClientSectorsAPI = this.clientApiCall(token, `industries` , "GET")
      this.getDocDownloadAPI = this.clientApiCall(token, `client_report_excel` , "GET")
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const isUpdate = shouldClientReportTableUpdate(prevState, this.state);
    if (isUpdate) {
      const {
        page,
        per_page,
        startDate,
        endDate,
        sectorSelection,
        clientSearch,
        country,
      } = this.state;
      const xState = {
        page,
        sectorSelection,
        clientSearch,
        per_page,
        country,
        startDate,
        endDate,
      }
      localStorage.setItem("client_table_states", JSON.stringify(xState));
      const cliUrl = `${window.location.pathname}?applied`;
      window.history.replaceState(null, '', cliUrl);
      const reportsEndpont = getClientReportEndpoint(this.state)
      const csvEndpoint = getClientReportCsvEndpoint(this.state)
      this.getClientDataAPI = this.clientApiCall(this.state.token, reportsEndpont, "GET")
      this.getDocDownloadAPI = this.clientApiCall(this.state.token, csvEndpoint , "GET")
    }
  }

  componentWillUnmount(): any {
    if (this.debounceTimeOutFunction) {
      clearTimeout(this.debounceTimeOutFunction);
    }
  }

  clearClientFilters = () => {
    this.setState({
      page: 1,
      sectorSelection:{
        id:"",
        name:""
      },
      per_page: 10,
      clientSearch:{
        id:"",
        name:""
      },
      country:"",
      startDate:"",
      endDate:"",
     }, () => {this.getClientDataAPI = this.clientApiCall(this.state.token, `get_client_report` , "GET")}
     )
  }

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>, client: Client) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedClient: client,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      selectedClient: null,
    });
  };

  handleMenuItemClick = (action: string) => {
    const { selectedClient } = this.state;
    if (selectedClient) {
      switch (action) {
        case 'kycForm':
          const kycRedirection = { id: selectedClient.id}
          this.props.navigation.navigate("AdminKyc1Review" , kycRedirection) ;
          break;
        case 'valueChain':
          const valueChainRedirection = { id: selectedClient.id}
          this.props.navigation.navigate("AdminEKYCevaluation" , valueChainRedirection) ;
          break;
        case 'factorAgreement':
          const factorRedirection = { id: selectedClient.id}
          this.props.navigation.navigate("FactorAgreemenetReview" , factorRedirection) ;
          break;
        case 'payments':
          const paymentRedirection = { id: selectedClient.id}
          this.props.navigation.navigate("AdminProcessPayment" , paymentRedirection) ;
          break;
        // case 'invoices':
        //   const invoiceRedirection = { id: selectedClient.id}
        //   this.props.navigation.navigate("AdminClientInvoicesTable" , invoiceRedirection) ;
        //   break;
        case 'markDelinquent':
          this.handleDelinquent(selectedClient.id , selectedClient.is_delinquent_enable)
          break;
        default:
          break;
      }
    }
    this.handleMenuClose();
  };

  handleClientGetResponse = (response:any) =>{
    const data = response;
    const total_count = data?.total_count || data.total_count || 0; 
    const filteredData = handleClientFilteration(data)
    this.setState({
      clientData:filteredData,
      count: Math.ceil(data.total_count / this.state.per_page || 1),
      isLoading:false,
      total_count:total_count
    })
  }

  handleDelinquent = (clientId : number , isDelinquent : boolean) => {
    this.setState({
      isDelinquent: isDelinquent
    })
    !isDelinquent ? this.handleMarKDelinquent(clientId) : this.handleUnMarKDelinquent(clientId)
  }

  handleMarKDelinquent = (clientId : number) => {
    this.setState({
      isMarkModalOpen:true,
      singleClientId:clientId.toString(),
    })
  }
  
  handleUnMarKDelinquent = (clientId : number) => {
    this.setState({
      isUnMarkModalOpen:true,
      singleClientId:clientId.toString(),
    })
  }

  handleModalClose = () => {
    this.setState({
      isUnMarkModalOpen:false,
      isMarkModalOpen:false
    })
  }

  handleApprove = () => {
    const { isDelinquent , singleClientId , token } = this.state
    if (isDelinquent) {
      this.unMarkDelinquentAPI = this.clientApiCall(token , `/mark_delinquent_fee?account_id=${singleClientId}&is_delinquent_enable=false` , "PUT")
    }else {
      this.markDelinquentAPI = this.clientApiCall(token , `/mark_delinquent_fee?account_id=${singleClientId}&is_delinquent_enable=true` , "PUT")
    }
  }

  handleDelinquentResponse = () => {
    this.handleModalClose()
    this.getClientDataAPI = this.clientApiCall(this.state.token, "get_client_report" , "GET")
  }

  handleFilter = (col: any, opt: any) => {
    if (col === "country_name") {
      if (this.state.country.length === opt.length) {
        this.setState({
          country: [],
        });
      } else {
        this.setState({
          country: opt,
        });
      }
    }
  };
  
  handleChangePage = (event: any, newPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: newPage,
    });  
  };

  handleClientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    this.setState({ clientSearch: { id: 0, name: inputValue } });
    if (inputValue === "") {
      this.setState({ clientOptionsResponse: [] });
    }
    if (this.debounceTimeOutFunction) {
      clearTimeout(this.debounceTimeOutFunction);
    }
    this.debounceTimeOutFunction = setTimeout(() => {
      this.getClientOptionsAPI = this.clientApiCall(this.state.token, `client_advance_search?search=${inputValue}`, "GET");
      this.setState({ isClientsLoad1: true })
    }, 400);
  };

  handleSelectClient = (event: any, client: any) => {
    if (client?.id) {
      this.setState({
        clientSearch: client,
      });
    } else {
      this.setState({ clientSearch: { id: 0, name: "" } });
    }
  };

  handleClientOptionsResponse = (response: any) => {
    if (response.data) {
      const filterOptionData = handleClientOptionFilter(response);
      this.setState({
        clientOptionsResponse:filterOptionData,
      })
    } else {
      this.setState({ clientOptionsResponse: [] });
    }
    this.setState({ isClientsLoad1: false });
  };

  handleSector = (event: any) => {
    const option = this.state.sectorOptionsResponse.filter(
      (option : any) => option.id == event.target.value
    );
    if (option.length > 0) {
      this.setState({ sectorSelection: option[0] });
    }
  };

  handleSectorAPICall = (response: any) => {
    const options = handleIndustryFilter(response);
    this.setState({
      sectorOptionsResponse: options
    })
  }

  handleDateChange = (startDate : string, endDate : string) => {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.getClientDataAPI){
      this.handleClientGetResponse(responseJson)
    }
    if(apiRequestCallId === this.unMarkDelinquentAPI || apiRequestCallId === this.markDelinquentAPI){
      this.handleDelinquentResponse()
    }
    if(apiRequestCallId === this.getClientSectorsAPI){
      this.handleSectorAPICall(responseJson)
    }
    if(apiRequestCallId === this.getClientOptionsAPI){
      this.handleClientOptionsResponse(responseJson)
    }
    if(apiRequestCallId === this.getDocDownloadAPI){
      responseJson?.url && this.setState({ CSVFileURL: responseJson.url});
    }
  }

  clientApiCall = (token: token, endPoint: string, method: string, body?: any) => {
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);
    return clientRequestMessage.messageId
  }
}
