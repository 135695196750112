// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { downloadDocs } from "../../../components/src/Utils/utils";
import { parseDataIntoOptions } from "../../../components/src/Utils/parseData";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  searchValue: { id: string | number; name: string };
  sort_column: string;
  sort_direction: string;
  page: number;
  per_page: number;
  total_count:number;
  role: any[];
  token: any;
  activityData: [];
  count: number;
  loadingTableData: boolean;
  searchLogOptions: any[];
  isLoadingOptions: boolean
}

interface SS {
  id: any;
}

export default class ActivityLogController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDataApi: string = "";
  getDocApi: string = "";
  getLogOptionsApi: string ="";
  debounceTimeout4: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      searchValue: { id: "", name: "" },
      sort_column: "",
      sort_direction: "",
      page: 1,
      per_page: 10,
      total_count:0,
      role: [],
      token: "",
      activityData: [],
      count: 0,
      loadingTableData: false,
      searchLogOptions: [],
      isLoadingOptions: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentWillUnmount(): any {
    if (this.debounceTimeout4) {
      clearTimeout(this.debounceTimeout4);
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem("auth_token");
    if (token) {
      this.setState({ token: token });
      this.getTableData(token);
    }
  }

  clearActivityFilters = () => {
    this.setState({
      sort_column: "",
      searchValue: { id: "", name: "" },
      sort_direction: "",
      page: 1,
      role: [],
      per_page: 10,
    }, () => this.getTableData(this.state.token))
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if( prevState.searchValue.name !== this.state.searchValue.name) {
      if (this.debounceTimeout4) {
        clearTimeout(this.debounceTimeout4);
      }
      this.debounceTimeout4 = setTimeout(() => {
        this.getSearchActivityOptions(this.state.searchValue.name);
      }, 300);
    }
    if (
      prevState.page !== this.state.page ||
      prevState.sort_direction !== this.state.sort_direction ||
      prevState.per_page !== this.state.per_page ||
      prevState.sort_column !== this.state.sort_column ||
      prevState.role.length !== this.state.role.length
    ) {
      this.state.token && this.getTableData(this.state.token);
    }
  }
  
  handleSearchActivity = () => {
    this.setState({
      sort_column: "",
      sort_direction: "",
      page: 1,
      per_page: 10,
    });
    if (this.state.token) {
      this.getTableData(this.state.token);
    }
  };

  handleSort = (columnName: string, direction: string) => {
    this.setState({
      sort_column: columnName,
      sort_direction: direction,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDocApi) {
          responseJson?.url && downloadDocs(responseJson.url);
        }
        if (apiRequestCallId === this.getDataApi) {
          this.getDataResponse(responseJson);
        }
        if (apiRequestCallId === this.getLogOptionsApi) {
          this.getCompanyOptionResponse(responseJson);
        }
      } else {
        this.setState({loadingTableData: false, isLoadingOptions: false})
      }
    }
  }

  getCompanyOptionResponse = (res: any) => {
    if (res?.data) {
      const parsedOptions4 = parseDataIntoOptions(res.data);
      this.setState({ searchLogOptions: parsedOptions4 });
    } else {
      this.setState({ searchLogOptions: [] });
    }
    this.setState({ isLoadingOptions: false });
  };

  handleFilter = (col: any, opt: any) => {
    if (col === "event") {
      if (this.state.role.length === opt.length) {
        this.setState({
          role: [],
        });
      } else {
        this.setState({
          role: opt,
        });
      }
    }
  };

  handleInputChangeActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    this.setState({ searchValue: { id: 0, name: inputValue } });
    if (inputValue === "") {
      this.setState({ searchLogOptions: [] });
    }
  };

  handleChangePage = (event: any, newPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      page: newPage,
    });  
  };
  
  handleSelectSearchActivity = (event: any, value4: any) => {
    if (value4?.id) {
      this.setState({ searchValue: value4 }, () => {
        this.handleSearchActivity();
      });
    } else {
      this.setState({ searchValue: { id: 0, name: "" } });
    }
  };

  getDataResponse = (res: any) => {
    if (res?.activity_logs?.data && res?.total_count) {
      const total_count = res.sectors?.total_count || res.total_count || 0; 
      const ceil = Math.ceil(res.total_count / this.state.per_page || 1);
      this.setState({
        count: ceil,
        activityData: res.activity_logs.data,
        loadingTableData: false,
        total_count:total_count
      });
    } else {
      this.setState({
        count: 0,
        activityData: [],
        loadingTableData: false
      });
    }
  };

  handleDownload = (doc: string) => {
    if (doc === "pdf") {
      this.getDocument("pdf");
    } else {
      this.getDocument("excel");
    }
  };

  getDocument = (doc: string) => {
    const header = {
      token: this.state.token,
    };

    const endPoint =
      doc === "excel"
        ? "excel_download"
        : "bx_block_activitylog/activity_logs/download_pdf";

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDocApi = requestMessages.messageId;

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
  };

  getTableData = (token: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const {
      searchValue,
      sort_column,
      sort_direction,
      page,
      per_page,
      role,
    } = this.state;

    const path = `search=${searchValue.name}&role=${role}&sort_column=${sort_column}&sort_direction=${sort_direction}&page=${page}&per_page=${per_page}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({loadingTableData: true})

    this.getDataApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_activitylog/activity_logs?${path}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchActivityOptions = (value: any) => {

    this.setState({ isLoadingOptions: true });
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.state.token,
      "Content-Type": configJSON.notificationApiContentType,
    };

    this.getLogOptionsApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_advance_search?search=${value}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
