export const styles: any = {
    mainChartContainer: {
        border: '1px solid #EFF0F6',
        padding: '25px 40px',
        background: '#FFFFFF',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    customTooltip: {
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        padding: '10px',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'flex-start',
        zIndex: 9999,
        width: '195px',
        borderRadius: '12px',
        gap: '10px'
    },
    tooltipColor: {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
    },
    tooltipContent: {
        flexGrow: 1,
        fontSize: '10px',
        fontWeight: 600,
        height: '45px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    tooltipLabel: {
        fontWeight: 'bold',
        margin: 0,
        maxWidth:"145px",
        overflow: 'hidden'
    },
    tooltipValue: {
        margin: 0,
    },
    sectorLableInsideTooltip: {
        minWidth:"145px",
        overflow: 'hidden'
    },
    tooltipPercent: {
        margin: 0,
        color: '#F3B229',
    },
    tilesAndButtonHolder: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    tilesContainer: {
        display: 'grid',
        gridGap: '10px 20px',
        marginTop: '20px',
        width: '45%',
        margin: '0 auto',
        gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
        gridTemplateRows: 'repeat(4, auto)',
        '@media (max-width: 768px)': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(100%, 1fr))',
        },
    },
    chartTile: {
        padding: '10px',
        position: 'relative',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        display: 'flex',
        alignItems: 'center',
    },
    tileColor: {
        width: '32px',
        height: '28px',
        display: 'inline-block',
        marginRight: '10px',
    },
    tileContent: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    tileLabel: {
        fontWeight: 'bold',
        margin: '0',
    },
    tileValue: {
        margin: '0',
    },
    tilePercent: {
        margin: '0',
    },
    tileTooltip: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.7)    ',
        color: 'white',
        padding: '10px',
        borderRadius: '12px',
        top: '-40px',
        left: '26px',
        transform: 'translateX(-50%)',
        opacity: '1',
        pointerEvents: 'none',
        transition: 'opacity 0.3s ease, top 0.3s ease',
        minHeight: '62px',
        width: 'max-content',
        minWidth: "145px",
        maxWidth: '14rem',
        zIndex: 1000,
        fontSize: '10px',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: '5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: 'transparent transparent rgba(0, 0, 0, 0.7) transparent',
            transform: 'rotate(180deg)',
        },
    },
    chartTileHover: {
        '&:hover $tileTooltip': {
            opacity: '1',
            top: '-40px',
            pointerEvents: 'auto',
        },
    },
    chartContainer: {
        position: 'relative',
        width:'100%',
        height:'360px',
        "& .textClassHolder":{
            fontSize:'10px !important',
            '@media (min-width: 420px) and (max-width: 660px)': {
                fontSize: '8px !important'
            },
            '@media (min-width: 210px) and (max-width: 420px)': {
                fontSize: '6px !important'
            },
        },
    },
    shadowCircle: {
        width: '65px',
        height: '65px',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.5)',
    },
    selectField: {
        margin: 0,
        height: '50px',
        width: '97%',
        "& .MuiSelect-root": {
            padding: "18px 14px",
            fontSize: "12px",
            lineHeight: "15px",
            fontWeight: 500,
            color: "#757575",
            backgroundColor:'white',
        },
        "& .Mui-focused": {
            color: 'red',
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e5b632 !important",
            },
        },
        "&::before":{
            border:'none !important',
        },
        "&::after":{
            border:'none'
        }
    },
    menuPaper: {
        marginTop: "-10px",
        border: "1px solid #E7E7E7",
        boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.2)",
        borderRadius: "4px",
        "& .MuiMenu-list": {
            padding: "5px 12px",
            "& li": {
                backgroundColor: "transparent !important",
                fonteight: 500,
                fontSize: "12px",
                lineHeight: "15px",
                letterSpacing: "-0.24px",
                color: "#757575",
                borderBottom: "1px solid #E7E7E7",
                "& :hover": {
                    backgroundColor: "transparent",
                }
            },
            "& li:last-child": {
                borderBottom: "none",
            },
            "& .Mui-selected": {
                color: "#e5b632",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
        },
    },
    borderProvide: {
        fontSize:'14px',
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e5b632",
            },
        },
    },
    flexProvider:{
        display: "flex",
        alignItems: "center",
        gap:'15px',
        fontWeight:900,
        fontSize:'18px',
        justifyContent: "center",
    },
    tilesHolder:{
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
    },
    // '@media (min-width: 601px) and (max-width: 960px)': {
    //     responsiveChartContainer: {
    //         width: "50% !important",
    //     },
    // },
}